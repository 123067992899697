import React, { PropsWithChildren } from "react"
import { Helmet } from "react-helmet"
import useSeo from "../hooks/use-seo"

export default function Layout({ children }: PropsWithChildren<{}>) {
  const { title, description, image, url, twitterUsername, favicon } = useSeo()

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitterUsername} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width, height=device-height, viewport-fit=cover"
        />
        <meta charSet="utf-8" />
        <link rel="icon" href={favicon}></link>
        <body className="min-h-screen dark:bg-gray-900 dark:text-white" />
      </Helmet>
      {children}
    </>
  )
}
