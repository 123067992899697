import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

type SeoData = {
  title: string
  description: string
  image: string
  url: string
  favicon: string
  twitterUsername: string
}

export default function useSeo(): SeoData {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          description
          siteUrl: url
          image
          favicon
          twitterUsername
        }
      }
    }
  `)

  const {
    title,
    description,
    siteUrl,
    image,
    favicon,
    twitterUsername,
  } = site.siteMetadata

  return {
    title,
    description,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname}`,
    favicon: `${siteUrl}${favicon}`,
    twitterUsername,
  }
}
